import React, { useState, useEffect, useCallback } from 'react';

const CreateRoom = ({ goBack, socket }) => {
  const [roomCode, setRoomCode] = useState('');
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [messageToSend, setMessageToSend] = useState('');

  console.log('CreateRoom rendered', { roomCode, password, nickname, messages });

  const containerStyle = {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '24rem',
  };

  const inputStyle = {
    width: '100%',
    padding: '0.5rem',
    marginBottom: '1rem',
    border: '1px solid #d1d5db',
    borderRadius: '0.25rem',
  };

  const buttonStyle = {
    width: '100%',
    padding: '0.5rem 1rem',
    backgroundColor: '#3b82f6',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  };

  const alertStyle = {
    padding: '0.5rem',
    backgroundColor: '#10b981',
    color: 'white',
    borderRadius: '0.25rem',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const errorStyle = {
    padding: '0.5rem',
    backgroundColor: '#ef4444',
    color: 'white',
    borderRadius: '0.25rem',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const messagesContainerStyle = {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#f3f4f6',
    borderRadius: '0.25rem',
    height: '300px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  };

  const messageStyle = (isHost) => ({
    maxWidth: '70%',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: isHost ? '#3b82f6' : '#e5e7eb',
    color: isHost ? '#ffffff' : '#000000',
    alignSelf: isHost ? 'flex-end' : 'flex-start',
  });

  const timestampStyle = {
    fontSize: '0.75rem',
    color: '#6b7280',
    marginTop: '0.25rem',
  };

  const handleCreateRoom = useCallback(() => {
    console.log('Attempting to create room...');
    const newRoomCode = Math.floor(100000 + Math.random() * 900000).toString();
    const newPassword = Math.random().toString(36).slice(-8);
    
    setErrorMessage('');
    console.log('Emitting createRoom event:', { roomCode: newRoomCode, password: newPassword, nickname });
    socket.emit('createRoom', { roomCode: newRoomCode, password: newPassword, nickname }, (response) => {
      console.log('Received response from createRoom:', response);
      if (response && response.success) {
        console.log('Room created successfully, updating state');
        setRoomCode(response.roomCode);
        setPassword(response.password);
        setNickname(response.nickname);
        console.log('State updated:', { roomCode: response.roomCode, password: response.password, nickname: response.nickname });
      } else {
        console.error('Failed to create room:', response ? response.message : 'No response');
        setErrorMessage(response ? response.message : 'Failed to create room. Please try again.');
      }
    });
  }, [socket, nickname]);

  const handleSendMessage = useCallback(() => {
    if (messageToSend.trim()) {
      console.log('Sending message:', messageToSend);
      socket.emit('sendMessage', { roomCode, message: messageToSend.trim(), sender: 'Host' });
      setMessageToSend('');
    }
  }, [roomCode, messageToSend, socket]);

  useEffect(() => {
    console.log('Setting up socket event listeners');

    const onUserJoined = ({ userId, nickname }) => {
      console.log('User joined event received', { userId, nickname });
      setIsUserConnected(true);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      setMessages(prevMessages => [...prevMessages, { 
        message: `${nickname} joined the room`, 
        sender: 'System', 
        timestamp: new Date().toISOString() 
      }]);
    };

    const onMessageReceived = (newMessage) => {
      console.log('Message received:', newMessage);
      setMessages(prevMessages => [...prevMessages, newMessage]);
    };

    socket.on('userJoined', onUserJoined);
    socket.on('messageReceived', onMessageReceived);

    return () => {
      console.log('Cleaning up socket event listeners');
      socket.off('userJoined', onUserJoined);
      socket.off('messageReceived', onMessageReceived);
    };
  }, [socket]);

  return (
    <div style={containerStyle}>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center', color: '#3b82f6' }}>Create Room</h2>
      
      {showAlert && (
        <div style={alertStyle}>
          User connected!
        </div>
      )}

      {errorMessage && (
        <div style={errorStyle}>
          {errorMessage}
        </div>
      )}

      {!roomCode ? (
        <div>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder="Enter your nickname (optional)"
            style={inputStyle}
          />
          <button style={buttonStyle} onClick={handleCreateRoom}>
            Generate Room
          </button>
        </div>
      ) : (
        <div>
          <p style={{ marginBottom: '0.5rem' }}><strong>Room Code:</strong> {roomCode}</p>
          <p style={{ marginBottom: '0.5rem' }}><strong>Password:</strong> {password}</p>
          <p style={{ marginBottom: '1rem' }}><strong>Your Nickname:</strong> {nickname}</p>
          <p style={{ fontSize: '0.875rem', color: '#4b5563' }}>Share these with the person you want to connect with.</p>
        </div>
      )}

      {roomCode && (
        <div style={messagesContainerStyle}>
          <h3 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Messages:</h3>
          {messages.length === 0 ? (
            <p style={{ textAlign: 'center', color: '#6b7280' }}>No messages yet</p>
          ) : (
            messages.map((msg, index) => (
              <div key={index} style={messageStyle(msg.sender === 'Host')}>
                <strong>{msg.nickname || msg.sender}: </strong>
                <span>{msg.message}</span>
                <div style={timestampStyle}>
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {roomCode && (
        <div>
          <input
            type="text"
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
            placeholder="Type a message"
            style={inputStyle}
          />
          <button onClick={handleSendMessage} style={buttonStyle}>
            Send Message
          </button>
        </div>
      )}

      <button style={{ ...buttonStyle, backgroundColor: '#9ca3af', marginTop: '1rem' }} onClick={goBack}>
        Back to Home
      </button>
    </div>
  );
};

export default CreateRoom;