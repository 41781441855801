import React, { useState, useEffect, useCallback } from 'react';

const JoinRoom = ({ goBack, socket, qrData }) => {
  const [roomCode, setRoomCode] = useState('');
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [messageToSend, setMessageToSend] = useState('');

  useEffect(() => {
    if (qrData) {
      setRoomCode(qrData.roomCode);
      setPassword(qrData.password);
    }
  }, [qrData]);

  useEffect(() => {
    console.log('Setting up socket event listeners for JoinRoom');

    const onRoomClosed = () => {
      console.log('Room closed event received');
      setIsJoined(false);
      setErrorMessage('The room has been closed by the creator.');
    };

    const onMessageReceived = (newMessage) => {
      console.log('Message received:', newMessage);
      setMessages(prevMessages => [...prevMessages, newMessage]);
    };

    socket.on('roomClosed', onRoomClosed);
    socket.on('messageReceived', onMessageReceived);

    return () => {
      console.log('Cleaning up socket event listeners for JoinRoom');
      socket.off('roomClosed', onRoomClosed);
      socket.off('messageReceived', onMessageReceived);
    };
  }, [socket]);

  const handleJoinRoom = useCallback(() => {
    console.log('Attempting to join room:', roomCode);
    if (roomCode && password) {
      socket.emit('joinRoom', { roomCode, password, nickname }, (response) => {
        console.log('Received response from joinRoom:', response);
        if (response.success) {
          console.log('Room joined successfully');
          setIsJoined(true);
          setErrorMessage('');
          setNickname(response.nickname);
          setMessages(response.messages);
        } else {
          console.error('Failed to join room:', response.message);
          setErrorMessage(response.message || 'Failed to join room. Please try again.');
        }
      });
    } else {
      setErrorMessage('Please enter both room code and password.');
    }
  }, [roomCode, password, nickname, socket]);

  const handleSendMessage = useCallback(() => {
    if (messageToSend.trim()) {
      console.log('Sending message:', messageToSend);
      socket.emit('sendMessage', { roomCode, message: messageToSend.trim(), sender: 'Guest' });
      setMessageToSend('');
    }
  }, [roomCode, messageToSend, socket]);

  const containerStyle = {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '24rem',
  };

  const inputStyle = {
    width: '100%',
    padding: '0.5rem',
    marginBottom: '1rem',
    border: '1px solid #d1d5db',
    borderRadius: '0.25rem',
  };

  const buttonStyle = {
    width: '100%',
    padding: '0.5rem 1rem',
    backgroundColor: '#10b981',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  };

  const errorStyle = {
    padding: '0.5rem',
    backgroundColor: '#ef4444',
    color: 'white',
    borderRadius: '0.25rem',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const messagesContainerStyle = {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#f3f4f6',
    borderRadius: '0.25rem',
    height: '300px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  };

  const messageStyle = (isGuest) => ({
    maxWidth: '70%',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: isGuest ? '#10b981' : '#e5e7eb',
    color: isGuest ? '#ffffff' : '#000000',
    alignSelf: isGuest ? 'flex-end' : 'flex-start',
  });

  const timestampStyle = {
    fontSize: '0.75rem',
    color: '#6b7280',
    marginTop: '0.25rem',
  };

  if (!isJoined) {
    return (
      <div style={containerStyle}>
        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center', color: '#10b981' }}>Join Room</h2>
        {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
        <input
          type="text"
          placeholder="Room Code"
          value={roomCode}
          onChange={(e) => setRoomCode(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Your Nickname (optional)"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          style={inputStyle}
        />
        <button
          onClick={handleJoinRoom}
          style={buttonStyle}
        >
          Join Room
        </button>
        <button
          onClick={goBack}
          style={{ ...buttonStyle, backgroundColor: '#9ca3af' }}
        >
          Back to Home
        </button>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center', color: '#10b981' }}>Room: {roomCode}</h2>
      <p style={{ marginBottom: '1rem' }}><strong>Your Nickname:</strong> {nickname}</p>
      <div style={messagesContainerStyle}>
        {messages.length === 0 ? (
          <p style={{ textAlign: 'center', color: '#6b7280' }}>No messages yet</p>
        ) : (
          messages.map((msg, index) => (
            <div key={index} style={messageStyle(msg.sender === 'Guest')}>
              <strong>{msg.nickname || msg.sender}: </strong>
              <span>{msg.message}</span>
              <div style={timestampStyle}>
                {new Date(msg.timestamp).toLocaleTimeString()}
              </div>
            </div>
          ))
        )}
      </div>
      <input
        type="text"
        value={messageToSend}
        onChange={(e) => setMessageToSend(e.target.value)}
        placeholder="Enter a message to send"
        style={inputStyle}
      />
      <button
        onClick={handleSendMessage}
        style={buttonStyle}
      >
        Send Message
      </button>
      <button
        onClick={() => {
          setIsJoined(false);
          setRoomCode('');
          setPassword('');
          setNickname('');
          setMessages([]);
          socket.emit('leaveRoom', { roomCode });
          goBack();
        }}
        style={{ ...buttonStyle, backgroundColor: '#9ca3af' }}
      >
        Leave Room
      </button>
    </div>
  );
};

export default JoinRoom;