import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const qrcodeRegionId = "html5qr-code-full-region";

const Html5QrcodePlugin = ({ fps, qrbox, disableFlip, qrCodeSuccessCallback, qrCodeErrorCallback }) => {
    useEffect(() => {
        // when component mounts
        const html5QrcodeScanner = new Html5QrcodeScanner(
            qrcodeRegionId, 
            { fps: fps || 10, qrbox: qrbox || 250, disableFlip: disableFlip || false },
            /* verbose= */ false);
        html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, [fps, qrbox, disableFlip, qrCodeSuccessCallback, qrCodeErrorCallback]);

    return (
        <div id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;