import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import CreateRoom from './CreateRoom';
import JoinRoom from './JoinRoom';
import Html5QrcodePlugin from './Html5QrcodePlugin';

const socket = io('https://kakure.io', {
  transports: ['websocket', 'polling'],
  path: '/socket.io/',
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  forceNew: true
});

/*
const socket = io('http://localhost:3001', {
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});
*/

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [isConnected, setIsConnected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [qrData, setQrData] = useState(null);

  const onConnect = useCallback(() => {
    console.log('Connected to server');
    setIsConnected(true);
  }, []);

  const onDisconnect = useCallback(() => {
    console.log('Disconnected from server');
    setIsConnected(false);
  }, []);

  const onConnectError = useCallback((error) => {
    console.error('Connection error:', error);
    setIsConnected(false);
  }, []);

  useEffect(() => {
    console.log('App component mounted');

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);

    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
    };
  }, [onConnect, onDisconnect, onConnectError]);

  const containerStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    backgroundColor: '#f3f4f6',
  };

  const buttonStyle = {
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    backgroundColor: '#3b82f6',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    try {
      const data = JSON.parse(decodedText);
      setQrData(data);
      setCurrentPage('join');
    } catch (error) {
      console.error("Invalid QR code data", error);
    }
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'create':
        return <CreateRoom goBack={() => setCurrentPage('home')} socket={socket} />;
      case 'join':
        return <JoinRoom goBack={() => setCurrentPage('home')} socket={socket} qrData={qrData} />;
      default:
        return (
          <div>
            <button style={buttonStyle} onClick={() => setCurrentPage('create')}>
              Create Room
            </button>
            <button style={buttonStyle} onClick={() => setCurrentPage('join')}>
              Join Room
            </button>
            {isMobile && (
              <div>
                <h2>Scan QR Code to Join Room</h2>
                <Html5QrcodePlugin
                  fps={10}
                  qrbox={250}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanResult}
                />
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '2rem', color: '#2563eb' }}>Kakure.io</h1>
      {isConnected ? renderPage() : <p>Connecting to server...</p>}
      <p>Connection status: {isConnected ? 'Connected' : 'Disconnected'}</p>
    </div>
  );
};

export default App;